import React from 'react'
import Header from '../components/Header'
import Cta from '../components/Cta'
import Footer from '../components/Footer'
import BreadcrumbArea from '../components/BreadcrumbArea'
import About from '../components/About'

import Testimonials from '../components/Testimonial';

import Ratio from 'react-bootstrap/Ratio';
import { Helmet } from 'react-helmet-async'
import ScrollToTop from '../components/ScrollToTop'

const AboutPage = () => {
    return (
        <>
            <Helmet>
                <title>Web3 and Blockchain Business and Technology Consultancy</title>
                <meta name="description" content="Melcom Copeland XSTRATEGY is a Web3, Gaming, Blockchain, Fintech, and Payments Management Consultancy and Executive Advisor to C-Suite start-ups and Singapore based Family Offices. We specialize in blockchain-based web3 and metaverse gaming payments infrastructure and solutions. " />
            </Helmet>
            <ScrollToTop />
            <Header />
            <BreadcrumbArea
                title="About us"
                subTitle="XSTRATEGY brings over 25-years of verifiable experience as experts in nascent and emerging software innovation, product development and go-to-market strategy for the real money gaming, fintech, and blockchain-based payments sectors. Our extended network of professional Executive Business Advisors and Technologists are ready to collaborate with C-Suite Executives and progressive Singapore-based Family Offices."
                action
            />
            <About />

            <div className="section-gap section-bg-switch">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="section-title mb-4">
                                <div className="main-title">Blockchain-based Web3 Business and Technology Solutions</div>
                                <div className="sub-title">
                                    Book a Comprehensive, Strategic Growth Session with Our Web3 Wizards
                                </div>
                            </div>
                            <a href="/#" className="btn btn-primary rounded-pill mb-5 mb-lg-0">Get a Proposal</a>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <Ratio aspectRatio="16x9">
                                <iframe width="473" height="841" src="https://www.youtube.com/embed/t6apO6IwBAU" title="Melcom Copeland Singapore-based Blockchain and Cryptocurrency Payments Consultant" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </Ratio>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-gap pb-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="section-title text-center mb-md">
                                <div className="main-title">
                                    Don't Just Trust Us, <span className="text-primary">Verify.</span>
                                </div>
                            </div>
                            <div className="testi-slider">
                                <Testimonials />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Cta background="section-bg-switch" />
            <Footer />
        </>
    )
}

export default AboutPage