import { ReactComponent as DoubleAngleRight } from '../assets/img/double-angle-right.svg';
import sideText1 from '../assets/img/sidetext-1.png';
import sideText2 from '../assets/img/sidetext-2.svg';
import sideText3 from '../assets/img/sidetext-3.svg';

import { ReactComponent as Vec1 } from '../assets/img/sidetext-vec-1.svg';
import { ReactComponent as Vec2 } from '../assets/img/sidetext-vec-2.svg';
import { ReactComponent as Pat1 } from '../assets/img/sidetext-pat-1.svg';

const SideText = () => {
  return (
    <>
      <div className="section-gap section-bg-switch position-relative z-1" id="services">
        <div className="position-absolute top-100 start-0 z-n1 translate-middle-y">
          <Vec1 />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title mb-5">
                <div className="main-title" data-aos="fade-up" data-aos-duration="600">Blockchain Banking</div>
                <div className="sub-title" data-aos="fade-up" data-aos-duration="600">
                  <p>XSTRATEGY is partnered with numerous crypto-friendly banks that provide comprehensive banking services.</p>
                  <p>For both established and young enterprises from the blockchain, fintech, and cryptocurrency sectors so that you can concentrate on your core business - developing and publishing games!</p>
                </div>
              </div>
              <a href="https://calendly.com/xstartegy/web3_advisory" className="btn btn-outline-primary rounded-pill" data-aos="fade-up" data-aos-duration="600">Learn More <DoubleAngleRight /></a>
            </div>
            <div className="col-lg-6">
              <div className="position-relative d-flex justify-content-end mt-5 mt-lg-0">
                <div className="position-absolute bg-danger top-0 left-0 h-100 w-100 mix-hue"></div>
                <img src={sideText1} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap position-relative z-1" id="family">
        <div className="position-absolute top-100 end-0 z-n1 translate-middle-y">
          <Vec2 />
        </div>
        <div className="position-absolute bottom-0 start-50 z-n1 ms-5 mb-5">
          <Pat1 />
        </div>
        <div className="position-absolute bottom-50 end-50 z-n1 me-5 mb-5 translate-middle-y">
          <Pat1 />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="section-title mb-5">
                <div className="main-title" data-aos="fade-up" data-aos-duration="600">Family Office Advisory</div>
                <div className="sub-title" data-aos="fade-up" data-aos-duration="600">
                  <p>XSTRATEGY offers comprehensive and customized Family Office strategic advisory services. We provide technical analysis and subject matter expertise (SME) in the areas of Fintech, Blockchain, Cryptocurrency and Gaming.</p>
                  <p>As a specialised partner, XSTRATEGY serves as an Interim Chief Information Officer (CIO) and Chief Technology Officer (CTO) to progressive Family Offices filling knowledge and competence gaps.</p>
                  <p>Ultra-wealthy families require professional and expert support more than ever before. Our focused attention is on building relationships with next-generation of Family Office leaders. </p>
                </div>
              </div>
              <a href="https://calendly.com/xstartegy/web3_advisory" className="btn btn-outline-primary rounded-pill" data-aos="fade-up" data-aos-duration="600">Contact us <DoubleAngleRight /></a>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="position-relative" data-aos="fade-up" data-aos-duration="600">
                <img src={sideText2} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap section-bg-blur section-bg-switch" id="legal">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title mb-5">
                <div className="main-title" data-aos="fade-up" data-aos-duration="600">Legal Advisory</div>
                <div className="sub-title" data-aos="fade-up" data-aos-duration="600">
                  <p>The legal compliance of your Web3 Gaming business is crucial for the success of your launch and ongoing gaming operations. Blockchain gaming projects and their digital economies supporting different transactions are legally complex.</p>
                  <p>Because of this, we work collaboratively with Blockchain leaders from specialised law firms to address regulatory concerns and seize strategic opportunities. Our network of Legal Advisors focus primarily on Blockchain, Smart Contracts and Cryptocurrency matters. Whether you are just getting started in blockchain or have deep industry expertise, we are here to help.</p>
                </div>
              </div>
              <a href="https://calendly.com/xstartegy/web3_advisory" className="btn btn-outline-primary rounded-pill">Learn More <DoubleAngleRight /></a>
            </div>
            <div className="col-lg-6">
              <div className="position-relative d-flex justify-content-end" data-aos="fade-up" data-aos-duration="600">
                <img src={sideText3} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SideText