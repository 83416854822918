import React from 'react';
import Slider from "react-slick";

// Logos
import logo1 from '../assets/img/brand-logo-1.png';
import logo2 from '../assets/img/brand-logo-2.png';
import logo3 from '../assets/img/brand-logo-3.png';
import logo4 from '../assets/img/brand-logo-4.png';
import logo5 from '../assets/img/brand-logo-5.png';
import logo6 from '../assets/img/brand-logo-6.png';
import logo7 from '../assets/img/brand-logo-7.png';
import logo8 from '../assets/img/brand-logo-8.png';
import logo9 from '../assets/img/brand-logo-9.png';
import logo10 from '../assets/img/brand-logo-10.jpg';
import logo11 from '../assets/img/brand-logo-11.png';
import logo12 from '../assets/img/brand-logo-12.png';
import logo13 from '../assets/img/brand-logo-13.png';
import logo14 from '../assets/img/brand-logo-14.png';
import logo15 from '../assets/img/brand-logo-15.png';
import logo16 from '../assets/img/brand-logo-16.png';

class SimpleSlider extends React.Component {
  render() {
    const settings = {
      speed: 5000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      swipeToSlide: true,
      variableWidth: true,
      draggable: false
    };
    return (
      <Slider {...settings} className="brands-wrapper">
        <div className='px-5'><img src={logo1} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo2} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo3} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo4} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo5} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo6} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo7} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo8} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo9} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo10} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo11} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo12} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo13} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo14} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo15} alt="" className="img-fluid brand-img" /></div>
        <div className='px-5'><img src={logo16} alt="" className="img-fluid brand-img" /></div>
      </Slider>
    );
  }
}

const Brands = () => {

  return (
    <>
      <SimpleSlider />
    </>
  )
}

export default Brands