import React from 'react'
import { ReactComponent as ArrowRight } from '../assets/img/arrow-right.svg';


const DigitalCard = ({icon, title, desc, link, linkText}) => {
  return (
    <div className="card-digital" data-aos="fade-up" data-aos-duration="600">
      {icon && <div className="icon-square icon-square-md icon-circle"> {icon} </div>}
      {title && <div className="card-title">{title}</div>}
      {desc && <div className="card-desc">{desc}</div>}
      {link && <a href={link} className="card-link">{linkText} <ArrowRight /> </a>}
    </div>
  )
}

const PlatformCard = ({icon, title, desc}) => {
  return (
    <div className="card-platform text-center" data-aos="fade-up" data-aos-duration="600">
      {icon && <div className="icon-square icon-square-md icon-circle mx-auto"> {icon} </div>}
      {title && <div className="card-title"> {title} </div>}
      {desc && <div className="card-desc"> {desc} </div>}
    </div>
  )
}

export {DigitalCard, PlatformCard}