import React from 'react'
import { ReactComponent as Rocket } from '../assets/img/icon-rocket.svg';
import { ReactComponent as Blur } from '../assets/img/hero-vector-bg-2.svg';
import circleText from '../assets/img/circle-text.svg';
import { ReactComponent as Mouse } from '../assets/img/mouse.svg';
import Brands from './Brands';


const Hero = () => {
  return (
    <div className="theme-hero bg-vector min-vh-100 d-flex flex-column align-items-center justify-content-center position-relative overflow-hidden">
      <div className="position-absolute bottom-0 start-0">
        <Blur />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="section-title text-center text-white">
              <div className="pre-title" data-aos="fade-up" data-aos-duration="400">Web3 Gaming</div>
              <div className="main-title position-relative z-1" data-aos="fade-up" data-aos-duration="600">Financial Services for <span className="text-bg-vector">Metaverse</span> and Web3 <br className='d-none d-xl-block' /> Gaming Platforms</div>
              <div className="sub-title col-lg-9 mx-auto" data-aos="fade-up" data-aos-duration="800">XSTRATEGY is a Singapore-based Web3 Management Consulting firm specializing in blockchain and cryptocurrency compliance, fintech, Decentralized Finance (DeFi), and digital payment solutions.</div>
            </div>
            <div className="d-flex justify-content-center">
              <a href="https://calendly.com/xstartegy/web3_advisory" className="btn btn-primary rounded-pill">
                Get Started <Rocket />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="z-1 w-100 pb-5 position-relative">
        <div className="brand-badge position-absolute top-0 end-0 mt-5 me-5 pe-xl-5 z-3 translate-middle-y pt-xl-5">
          <div className="position-relative mt-5">
            <img src={circleText} alt="" className="img-fluid animate-spin" />
            <Mouse className="position-absolute top-50 start-50 translate-middle" />
          </div>
        </div>
        <div className="overflow-hidden mt-5 pb-5">
          <div className="container-fluid theme-brands-hero">
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="col-xl-3">
                  <p className="ps-4 text-center text-xl-start mb-4 mb-lg-0">Trusted by innovative <br className='d-none d-xl-block' /> market leading Web 2.0 <br className='d-none d-xl-block' /> and Web 3.0 companies</p>
                </div>
                <div className="col-xl-9">
                    <Brands />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero