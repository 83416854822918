import React, { useEffect } from 'react';
import { gsap, ScrollTrigger } from "gsap/all";

import {ReactComponent as Info} from '../assets/img/icon-info.svg';
import {ReactComponent as BgBlur1} from '../assets/img/digital-bg-blur-1.svg';
import {ReactComponent as BgBlur2} from '../assets/img/digital-bg-blur-2.svg';
import {ReactComponent as EntIcon1} from '../assets/img/ent-icon-1.svg';
import {ReactComponent as EntIcon2} from '../assets/img/ent-icon-2.svg';
import {ReactComponent as EntIcon3} from '../assets/img/ent-icon-3.svg';
import {ReactComponent as EntIcon4} from '../assets/img/ent-icon-4.svg';

import lion from '../assets/img/lion-bg.png';
import {DigitalCard} from '../components/Card';

const DigitalEnt = () => {

  useEffect(() => {
    const card = Array.from(document.querySelectorAll('.card-digital'));
    const area = document.querySelector('.theme-digital');
    
    window.addEventListener('scroll', function () {
      if (window.scrollY + window.innerHeight > area.offsetTop && window.scrollY < (area.offsetTop + area.clientHeight)) {
        card.forEach((el, i) => {
          if (el.offsetTop + area.offsetTop < window.scrollY + window.innerHeight) {
            document.querySelectorAll('.scroll-sticky-count .count')[i].classList.add('active');
          } else {
            document.querySelectorAll('.scroll-sticky-count .count')[i].classList.remove('active')
          }
        });
      }
    })

    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.sticky-element',
            scrub: true,
            start: "0% 0%",
            end: "100% 0%",
            pin: true
          }
        });
    });

    return () => ctx.revert();

  },[])

  return (
    <div className="theme-digital bg-dark position-relative overflow-hidden z-1">
      <div className="position-absolute top-0 start-0">
        <BgBlur1 />
      </div>
      <div className="position-absolute top-0 end-0 translate-middle-y">
        <BgBlur2 />
      </div>
      <div className="position-absolute top-0 start-50 mt-5 translate-middle-x">
        <img src={lion} alt="" className="img-fluid" />
      </div>
      <div className="container z-2 position-relative">
        <div className="row">
          <div className="col-xl-6">
            <div className="sticky-element section-gap pb-0 mt-4">
              <div className="scroll-sticky-count text-white d-inline-flex gap-3 mb-4 mb-xl-5">
                <div className="count">01</div>
                <div className="count">02</div>
                <div className="count">03</div>
                <div className="count">04</div>
              </div>
              <div className="section-title mb-md text-white text-light">
                <div className="pre-title">digital entertainment</div>
                <div className="main-title">Web3 Gaming and Digital Entertainment Brands</div>
                <div className="sub-title">
                  <p>Through white-label or bespoke custodial or non-custodial wallet solutions, Web3 Gaming companies can access an extensive global blockchain-based payments network for real-time payment mechanism (RTP), cross-border payments processing and local acquiring with advanced business intelligence and real-time fraud prevention.</p>
                  <p>XSTRATEGY has partnered with best-in-class, blockchain native technology stacks that make-up a secure, composable, blockchain agnostic financial ecosystem in order to provide uninterrupted gaming experiences with increased payments convenience and speed.</p>
                </div>
              </div>
              <div className="d-flex">
                <a href="https://calendly.com/xstartegy/web3_advisory" className="btn btn-primary rounded-pill">
                  Explore More <Info />
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="d-flex flex-column gap-4 section-gap sticky-count">
              <DigitalCard
                icon={<EntIcon1 />}
                title="Mobile and Web SDK"
                desc="Provide fast, frictionless payment experiences in iOS and Android mobile apps and web3 e-marketplace websites for players everywhere."
                link="#"
                linkText="Learn More"
              />
              <DigitalCard
                icon={<EntIcon2 />}
                title="Reduce development costs"
                desc="By eliminating integration time, reducing investment cost and relying on XSTRATEGY Product Owners and Development Teams to continuously provide product enhancement and support services."
                link="#"
                linkText="Learn More"
              />
              <DigitalCard
                icon={<EntIcon3 />}
                title="Accelerate time to market"
                desc="Our C-Suite Payment Business and Technology Consultants will provide expert guidance and streamline the process of formulating your digital gaming economy and go-to-market strategies."
                link="#"
                linkText="Learn More"
              />
              <DigitalCard
                icon={<EntIcon4 />}
                title="Increase conversions and sales"
                desc="Through a customizable, in-app, white-label, native mSDK including one-click checkout for both traditional fiat and blockchain-based alternative payment methods."
                link="#"
                linkText="Learn More"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DigitalEnt