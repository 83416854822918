import React from 'react'
import { ReactComponent as Rocket } from '../assets/img/icon-rocket.svg';
import { ReactComponent as Blur } from '../assets/img/bc-blur.svg';
import Object1 from '../assets/img/bc-object-1.png';
import Object2 from '../assets/img/bc-object-2.png';

const BreadcrumbArea = ({title, subTitle, action}) => {
  return (
    <div className="theme-hero bc-area section-gap position-relative d-flex align-items-center justify-content-center z-2">
        <div className="position-absolute bottom-0 mb-5 object-1 d-none d-lg-inline-flex">
            <img src={Object1} alt="" className="img-fluid" />
        </div>
        <div className="position-absolute bottom-0 mb-5 object-2 d-none d-lg-inline-flex">
            <img src={Object2} alt="" className="img-fluid" />
        </div>
        <div className="position-absolute top-0 end-0 z-n1">
            <Blur />
        </div>

        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <div className="section-title text-center text-white mb-md">
                        <div className="main-title">{title}</div>
                        <div className="sub-title">{subTitle}</div>
                    </div>
                    {action && 
                    <div className="d-flex justify-content-center">
                        <a href="https://calendly.com/xstartegy/web3_advisory" className="btn btn-outline-primary text-white rounded-pill"> Get Started <Rocket /> </a>
                    </div>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default BreadcrumbArea