import React from 'react';
import Slider from "react-slick";

// Logos
import client1 from '../assets/img/client-1.jpg';
import client2 from '../assets/img/client-2.jpg';
import client3 from '../assets/img/client-3.jpg';
import client4 from '../assets/img/client-4.jpg';
import client5 from '../assets/img/client-5.jpg';
import client6 from '../assets/img/client-6.jpg';


// import vec1 from '../assets/img/testi-vec-1.webp';
// import vec2 from '../assets/img/testi-vec-2.webp';
// import vec3 from '../assets/img/testi-vec-3.webp';

class SimpleSlider extends React.Component {
    render() {
        const settings = {
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
        };
        return (
            <Slider {...settings}>
                <div className='testi-slide d-flex flex-column align-items-center'> 
                    <div className="client-quote">
                        Melcom has truly been an inspiration to work with. You won't find a more passionate, knowledgeable and hard-working professional, and I am happy and honoured to be able to have such a close working relationship with him. There is no doubt in my mind that any company, group or team with Melcom at the helm is destined for success.
                    </div>
                    <div className="icon-square rounded-pill mx-auto overflow-hidden">
                        <img src={client3} alt="" className="img-fluid" />
                    </div>
                    <div className='text-center mt-3'>
                        <div className="h5 fw-bold">
                            Boris Ahrendt
                        </div>
                        <p>
                            Director Gaming Product Management, PlaySQR
                        </p>
                    </div>
                </div>
                <div className='testi-slide d-flex flex-column align-items-center'> 
                    <div className="client-quote">
                        Melcom has a transparent management style actively encouraging communication between Business and IT. His wealth of gaming experience was refreshing in a new company, and in a new industry while assisting in building the foundations of an excellent Management and Operations Team.
                    </div>
                    <div className="icon-square rounded-pill mx-auto overflow-hidden">
                        <img src={client1} alt="" className="img-fluid" />
                    </div>
                    <div className='text-center mt-3'>
                        <div className="h5 fw-bold">
                            Paul Barker
                        </div>
                        <p>
                            Chief Technology Officer at Turicum Private Bank
                        </p>
                    </div>
                </div>
                <div className='testi-slide d-flex flex-column align-items-center'> 
                    <div className="client-quote">
                        Mr. Copeland's understanding of the gaming sector from all aspects, coupled with his astute business acumen ensures his clients continue to grow their relationship laterally whilst keeping ahead of the competition. Having grown revenue working with XSTRATEGY's expert guidance and solid product offerings, leaves me with no doubt that Mr Copeland and his team are by far one of the most reliable, knowledgeable and wholly integral business outfits that fulfils above and beyond the call of duty for their clients.
                    </div>
                    <div className="icon-square rounded-pill mx-auto overflow-hidden">
                        <img src={client2} alt="" className="img-fluid" />
                    </div>
                    <div className='text-center mt-3'>
                        <div className="h5 fw-bold">
                            Adam White
                        </div>
                        <p>
                            Director at Diocles Sdn Bhd
                        </p>
                    </div>
                </div>
                <div className='testi-slide d-flex flex-column align-items-center'> 
                    <div className="client-quote">
                        I have worked with Melcom for the last couple of years and his gaming industry knowledge and passion for his products is tremendous. He is very open and has great business acumen.
                    </div>
                    <div className="icon-square rounded-pill mx-auto overflow-hidden">
                        <img src={client4} alt="" className="img-fluid" />
                    </div>
                    <div className='text-center mt-3'>
                        <div className="h5 fw-bold">
                            Graham Boyle
                        </div>
                        <p>
                            Director at Global Executive Consulting
                        </p>
                    </div>
                </div>
                <div className='testi-slide d-flex flex-column align-items-center'> 
                    <div className="client-quote">
                        Melcom is a resourceful, creative, and solution-oriented person who was frequently able to come up with new and innovative approaches to his assigned projects. He functioned well as a leader and is also able to work effectively as a team member.
                    </div>
                    <div className="icon-square rounded-pill mx-auto overflow-hidden">
                        <img src={client5} alt="" className="img-fluid" />
                    </div>
                    <div className='text-center mt-3'>
                        <div className="h5 fw-bold">
                            Douglas Thouy
                        </div>
                        <p>
                            System Administrator
                        </p>
                    </div>
                </div>
                <div className='testi-slide d-flex flex-column align-items-center'> 
                    <div className="client-quote">
                        Verbum meum dictum were the words inscribed on the London Stock Exchange doors over 200 hundred years ago. 'My word is my bond'. This best sums up Melcom Copeland and what is still a particularly rare breed of individual.
                    </div>
                    <div className="icon-square rounded-pill mx-auto overflow-hidden">
                        <img src={client6} alt="" className="img-fluid" />
                    </div>
                    <div className='text-center mt-3'>
                        <div className="h5 fw-bold">
                            Leon Benjamin
                        </div>
                        <p>
                            Director, Bluescape
                        </p>
                    </div>
                </div>
            </Slider>
        );
    }
}

const Testimonials = () => {

    return (
        <>
            <SimpleSlider />
        </>
    )
}

export default Testimonials;