import React from 'react';
import {ReactComponent as MissionVector} from '../assets/img/mission-vector.svg';
import {ReactComponent as DoubleAngleRight} from '../assets/img/double-angle-right.svg';

const Mission = () => {
  return (
    <div className="section-gap z-2 bg-white position-relative" id="mission">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <div className="pre-title" data-aos="fade-up" data-aos-duration="600">Our Mission</div>
              <div className="main-title" data-aos="fade-up" data-aos-duration="600">What We Do</div>
              <div className="sub-title" data-aos="fade-up" data-aos-duration="600">
                The XSTRATEGY team provides trusted guidance and advice to Web3 Founders, C-Suite Executives, and Singapore-based Family Offices who are building and investing in the blockchain gaming and metaverse industries.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="d-flex justify-content-center justify-content-lg-start mb-5 mb-lg-0">
              <MissionVector />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-title text-light mb-md">
              <div className="main-title" data-aos="fade-up" data-aos-duration="600">
                Enabling Next-Generation Crypto Payments
              </div>
              <div className="sub-title" data-aos="fade-up" data-aos-duration="600">
                <p>We are subject matter experts in delivering compliant blockchain-based payments software and solutions that power the global web3 gaming and metaverse digital economies.</p>
                <p>Provisioning of seamless fiat and crypto on-off ramps, over the counter (OTC) trading, digital asset payment, and fraud prevention for the Blockchain.</p>
              </div>
            </div>
            <div className="d-flex" data-aos="fade-up" data-aos-duration="600">
              <a href="/#" className="btn btn-outline-primary rounded-pill">Learn More <DoubleAngleRight /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mission