import React from 'react'
import Header from '../components/Header';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import BreadcrumbArea from '../components/BreadcrumbArea';

import Brands from '../components/Brands';
import Testimonials from '../components/Testimonial';

import Rocket from '../assets/img/contact-rocket.webp';
import Award from '../assets/img/contact-award.webp';
import Gear from '../assets/img/contact-gear.webp';
import Handshake from '../assets/img/contact-handshake.webp';
import Heart from '../assets/img/contact-heart.webp';
import { Helmet } from 'react-helmet-async';
import ScrollToTop from '../components/ScrollToTop';

const ContactPage = () => {
    return (
        <>
            <Helmet>
                <title>Web3 and Blockchain Business and Technology Consultancy</title>
                <meta name="description" content="Melcom Copeland XSTRATEGY is a Web3, Gaming, Blockchain, Fintech, and Payments Management Consultancy and Executive Advisor to C-Suite start-ups and Singapore based Family Offices. We specialize in blockchain-based web3 and metaverse gaming payments infrastructure and solutions. " />
            </Helmet>
            <ScrollToTop />
            <Header />

            <BreadcrumbArea
                title="Contact us"
                subTitle="Book a free consultation with our Web3 experts (Please ensure to select your local time zone while you book the meeting.)"
            />

            <div className="cal-height d-flex position-relative z-2">
                <iframe src="https://calendly.com/xstartegy/web3_advisory" className='h-100 w-100 position-absolute top-0 left-0' title="Calendly"></iframe>    
            </div>

            <div className="section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <div className="main-title">Web2 and Web3 Projects That Trust Us</div>
                            </div>
                            <Brands />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-gap pb-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="section-title text-center mb-md">
                                <div className="main-title">
                                    Don't Just Trust Us, <span className="text-primary">Verify.</span>
                                </div>
                            </div>
                            <div className="testi-slider">
                                <Testimonials />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-gap pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <div className="main-title">Why You Should Work With Us</div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row g-4 justify-content-center">
                                <div className="col-xl-2 col-md-3 col-6">
                                    <img src={Award} alt="" className="img-fluid px-5" />
                                    <br />
                                    <div className="fw-bold text-center">Award-winning Team</div>
                                </div>
                                <div className="col-xl-2 col-md-3 col-6">
                                    <img src={Handshake} alt="" className="img-fluid px-5" />
                                    <br />
                                    <div className="fw-bold text-center">Strategic Partnerships</div>
                                </div>
                                <div className="col-xl-2 col-md-3 col-6">
                                    <img src={Heart} alt="" className="img-fluid px-5" />
                                    <br />
                                    <div className="fw-bold text-center">Shared Ownership</div>
                                </div>
                                <div className="col-xl-2 col-md-3 col-6">
                                    <img src={Gear} alt="" className="img-fluid px-5" />
                                    <br />
                                    <div className="fw-bold text-center">Tailored Strategies</div>
                                </div>
                                <div className="col-xl-2 col-md-3 col-6">
                                    <img src={Rocket} alt="" className="img-fluid px-5" />
                                    <br />
                                    <div className="fw-bold text-center">Skill Sharing</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>

            <Cta background="bg-white" />

            <Footer />
        </>
    )
}

export default ContactPage