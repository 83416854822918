import { useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from '../assets/img/logo.png';
import {ReactComponent as Rocket} from '../assets/img/icon-rocket.svg';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Header() {
  const deSelectRef = useRef(null);
    const [navExpand, setNavExpand] = useState(false);

    useEffect(() => {

        function handleClickOutside(event) {
            if (deSelectRef.current && !deSelectRef.current.contains(event.target)) {
                setNavExpand(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [deSelectRef]);

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('load', stickNavbar);
    };
  }, [])


  const [stickyClass, setStickyClass] = useState('');

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100 ? setStickyClass('bg-black sticky-navbar') : setStickyClass('');
    }
  };


  return (
    <Navbar collapseOnSelect expand="xl" variant="dark" fixed="top" className={stickyClass} expanded={navExpand} ref={deSelectRef}>
      <Container>
        <HashLink to="/#home" className="navbar-brand">
          <img src={logo} alt="" className="img-fluid" onClick={() => setNavExpand(false)} />
        </HashLink>
        <div className="d-none d-sm-flex d-xl-none gap-2 ms-auto me-2">
          <a href="https://calendly.com/xstartegy/web3_advisory" className="btn btn-sm btn-primary rounded-pill">
            Get Started <Rocket />
          </a>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="border-0" onClick={() => setNavExpand(!navExpand)} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto" onClick={() => setNavExpand(false)}>
            <Link to="/about" className="nav-link">About</Link>
            <HashLink smooth to="/#solutions" className="nav-link">Solutions</HashLink>
            <HashLink smooth to="/#services" className="nav-link">Services</HashLink>
            <Link to="/contact" className="nav-link">Contact</Link>
          </Nav>
          <div className="d-flex d-sm-none d-xl-flex gap-2 mt-4 mt-xl-0">
            <a href="https://calendly.com/xstartegy/web3_advisory" className="btn btn-primary rounded-pill">
              Get Started <Rocket />
            </a>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;