import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import logoDark from '../assets/img/logo-dark.png';
import {ReactComponent as Linkedin} from '../assets/img/linkedin.svg';
import {ReactComponent as Twitter} from '../assets/img/twitter.svg';
import {ReactComponent as Instagram} from '../assets/img/instagram.svg';

const Footer = () => {
  return (
    <div className="bg-dark">

      <div className="theme-footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="footer-content mb-5 mb-lg-0">
                  <a href="/#">
                    <img src={logoDark} alt="" className="img-fluid" />
                  </a>
                  <p className="my-4">
                    XSTRATEGY is a Singapore-based Web3 Management Consulting firm specializing in blockchain and cryptocurrency compliance, fintech, Decentralized Finance (DeFi), and digital payment solutions.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 offset-lg-1">
                <div className="footer-widget">
                  <div className="widget-title">Company</div>
                  <ul>
                    <li><Link to="/about">About us</Link></li>
                    <li><a href="https://medium.com/@melcom.copeland">Blogs</a></li>
                    <li><Link to="/contact">Contact</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-lg-2">
                <div className="footer-widget">
                  <div className="widget-title">Solutions</div>
                  <ul>
                    <li><HashLink smooth to="/#solutions">Transaction Gateway</HashLink></li>
                    <li><HashLink smooth to="/#solutions">Blockchain Wallet</HashLink></li>
                    <li><HashLink smooth to="/#solutions">Fiat-Crypto On/Off Ramps</HashLink></li>
                    <li><HashLink smooth to="/#solutions">Crypto Point-of-Sale Device</HashLink></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-lg-2">
                <div className="footer-widget">
                  <div className="widget-title">Services</div>
                  <ul>
                    <li><HashLink smooth to="/#mission">C-Suite Advisory</HashLink></li>
                    <li><HashLink smooth to="/#services">Blockchain Banking</HashLink></li>
                    <li><HashLink smooth to="/#family">Family Office Advisory</HashLink></li>
                    <li><HashLink smooth to="/#legal">Legal Advisory</HashLink></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row g-3">
              <div className="col-lg-6">
                <div className="footer-copyright">
                  &copy; 2023 &nbsp; <a href="/#">XSTRATEGY</a>. All Rights Reserved
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex align-items-center justify-content-lg-end h-100">
                  <div className="social-links">
                    <a href="https://www.linkedin.com/in/melcomcopeland" target='_blank'>
                      <Linkedin />
                    </a>
                    <a href="https://twitter.com/MelcomCopeland" target='_blank'>
                      <Twitter />
                    </a>
                    <a href="https://www.instagram.com/melcom_copeland/" target='_blank'>
                      <Instagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Footer