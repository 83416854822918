import React from 'react'
import { PlatformCard } from './Card';

import { ReactComponent as PlatIcon1 } from '../assets/img/platform-icon-1.svg';
import { ReactComponent as PlatIcon2 } from '../assets/img/platform-icon-2.svg';
import { ReactComponent as PlatIcon3 } from '../assets/img/platform-icon-3.svg';
import { ReactComponent as PlatIcon4 } from '../assets/img/platform-icon-4.svg';
import { ReactComponent as PlatIcon5 } from '../assets/img/platform-icon-5.svg';
import { ReactComponent as PlatIcon6 } from '../assets/img/platform-icon-6.svg';

import { ReactComponent as Blur } from '../assets/img/platform-blur.svg';
import { ReactComponent as Blur2 } from '../assets/img/platform-blur-2.svg';


const Platform = () => {
  return (
    <div className="section-gap bg-dark position-relative z-1" id="solutions">
      <div className="position-absolute bottom-50 start-0 z-n1">
        <Blur />
      </div>
      <div className="position-absolute bottom-0 end-0 z-n1">
        <Blur2 />
      </div>
      <div className="container" id="solutions">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-white text-center">
              <div className="pre-title">payment platform</div>
              <div className="main-title">Our Blockchain-based digital payment platform</div>
              <div className="sub-title">Through white-label or bespoke custodial or non-custodial wallet solutions,
                Web3 Gaming companies can access multi-channel alternative payment methods that support an unlimited number of digital assets and cryptocurrencies</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container section-platform">
        <div className="row g-5">
          <div className="col-xl-4 col-md-6">
            <PlatformCard
              icon={<PlatIcon1 />}
              title="Fast"
              desc="Transactions settle in less than 5 seconds."
            />
          </div>
          <div className="col-xl-4 col-md-6">
            <PlatformCard
              icon={<PlatIcon2 />}
              title="Scalable"
              desc="Process thousands of transactions/second."
            />
          </div>
          <div className="col-xl-4 col-md-6">
            <PlatformCard
              icon={<PlatIcon3 />}
              title="Secure"
              desc="Significantly reduces the potential for fraud."
            />
          </div>
          <div className="col-xl-4 col-md-6">
            <PlatformCard
              icon={<PlatIcon4 />}
              title="Borderless"
              desc="Payments are global. The location of the sender or does not matter."
            />
          </div>
          <div className="col-xl-4 col-md-6">
            <PlatformCard
              icon={<PlatIcon5 />}
              title="Inclusive"
              desc="Anyone with a smartphone can participate."
            />
          </div>
          <div className="col-xl-4 col-md-6">
            <PlatformCard
              icon={<PlatIcon6 />}
              title="Efficient"
              desc="Uses an efficient proof-of-stake carbon-neutral blockchain."
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Platform