import React, { useEffect } from 'react';
import Header from '../components/Header'
import Hero from '../components/Hero'
import Mission from '../components/Mission'
import DigitalEnt from '../components/DigitalEnt'
import Platform from '../components/Platform'
import SideText from '../components/SideText'
import Cta from '../components/Cta'
import Footer from '../components/Footer'
import ScrollToTop from '../components/ScrollToTop'
import { Helmet } from 'react-helmet-async';

const Home = () => {

  useEffect(() => {
    const targets = document.querySelectorAll('[data-aos]');

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {

        if (entry.isIntersecting) {
          entry.target.classList.add('aos-animate');
        }
      });
    });

    targets.forEach(target => observer.observe(target));
  }, []);

  return (
    <div className="overflow-hidden">
      <Helmet>
        <title>Web3 and Blockchain Business and Technology Consultancy</title>
        <meta name="description" content="Melcom Copeland XSTRATEGY is a Web3, Gaming, Blockchain, Fintech, and Payments Management Consultancy and Executive Advisor to C-Suite start-ups and Singapore based Family Offices. We specialize in blockchain-based web3 and metaverse gaming payments infrastructure and solutions. " />
      </Helmet>
      <span id="home"></span>
      <ScrollToTop />
      <Header />
      <Hero />
      <Mission />
      <DigitalEnt />
      <Platform />
      <SideText />
      <Cta />
      <Footer />
    </div>
  )
}

export default Home