import React from 'react';
import person from '../assets/img/about-person.png';
import { ReactComponent as Discord } from '../assets/img/discord.svg';
import { ReactComponent as Twitter } from '../assets/img/twitter.svg';
import { ReactComponent as Facebook } from '../assets/img/facebook.svg';
import { ReactComponent as Youtube } from '../assets/img/youtube.svg';
import { ReactComponent as BlogIcon } from '../assets/img/about-blog-icon.svg';

const About = () => {
  return (
    <div className="section-gap bg-dark text-white">
      <div className="container z-3 position-relative">
        <div className="row g-5">
          <div className="col-lg-6">
            <img src={person} alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6">
            <div className="about-content ps-xl-5">
              <div className="intro mb-3">
                <div className="display-5 fw-extrabold">MELCOM COPELAND</div>
                <p className='fs-5'>Founder of XSTRATEGY PTE LTD</p>
              </div>
              <a href="https://medium.com/@melcom.copeland" className="btn btn-info my-3">
                <BlogIcon />
                Read my blog
              </a>
              <div className="mt-3 mb-5">
                <p>Melcom is the founder of XSTRATEGY PTE LTD. with a commercial career spanning over 25-years in California's Silicon Valley, UK, Europe, Asia and Africa. With a B.A. in Biological Sciences, and Master of Research (MRes) in Computing Science, he has spent over 17-years as a business developer, technologist, and investor in the Real Money Gaming industry.</p>
                <p>As an Innovator and Subject Matter Expert in Fintech and Blockchain-based payments the past 5-years, Melcom has been closely involved in the architecture, product development, go-to-market strategy, and monetisation of high-frequency cryptocurrency trading and digital asset payment solutions.</p>
                <p>With XSTRATEGY, Melcom serves as a Confidant and Advisor to blockchain-based emerging technology CEOs and Founders while also serving as a Strategic Technology Advisor to Singapore-based Family Offices.</p>
              </div>
              <div className="row g-4">
                <div className="col-sm-6">
                  <div className="fs-5 fw-bold mb-3">Regions</div>
                  <p className="fs-6">APAC, MENA, North America and Africa.</p>
                </div>
                <div className="col-sm-6">
                  <div className="fs-5 fw-bold mb-3">Based in</div>
                  <p className="fs-6">Singapore</p>
                </div>
                <div className="col-sm-6">
                  <div className="fs-5 fw-bold mb-3">Interests</div>
                  <p className="fs-6">Sports, Emerging Technologies, Art, Travel, Entrepreneurship</p>
                </div>
                <div className="col-sm-6">
                  <div className="fs-5 fw-bold mb-3">Services</div>
                  <p className="fs-6">Strategic Technology Advisor, C-Suite Executive Advisor</p>
                </div>
                <div className="col-lg-12">
                  <div className="fs-5 fw-bold mb-3">Contact me</div>
                  <div className="social-links">
                    <a href="/#">
                      <Discord />
                    </a>
                    <a href="/#">
                      <Twitter />
                    </a>
                    <a href="/#">
                      <Facebook />
                    </a>
                    <a href="/#">
                      <Youtube />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About