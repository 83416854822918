import React from 'react'

import { ReactComponent as ArrowTopRightCircle } from '../assets/img/arrow-top-right-circle.svg';


const Cta = ({background}) => {
  return (
    <div className={`bg-half-black position-relative section-gap pt-0 ${background ? background : 'section-bg-switch'}`}>
      <div className="container">
        <div className="theme-cta">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h3 className='fw-bold'>Get In Touch</h3>
              <p className='mb-0'>The Blockchain-based Financial Service is a fast-moving industry. To keep up and learn about the latest developments in the space, we encourage you to get in touch now for a free 30-minute consultation!</p>
            </div>
            <div className="col-lg-6">
              <div className="d-flex justify-content-lg-end mt-5 mt-lg-0">
                <a href="https://calendly.com/xstartegy/web3_advisory" className="btn btn-light rounded-pill">Contact Us <ArrowTopRightCircle /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cta